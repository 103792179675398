








import { Vue, Component } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import { Positions } from '@/shared/interfaces/classes/form.interfaces'
import tokenHelper from '@/shared/helpers/token.helper'
import { AuthActions } from '@/shared/store/auth/auth.actions'
import Form from '@/shared/components/form/Form.vue'
import __ from '@/shared/helpers/__'
import AuthLayout from '@/company/layouts/AuthLayout.vue'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { CompanyRoutes } from '@/shared/router/company'
import Company from '@/shared/modules/company/company.model'
import User from '@/shared/modules/user/user.model'
import { CompanyActions } from '@/shared/store/company/company.actions'
import { AdminRoutes } from '@/shared/router/admin'
import { RoleLevel } from '@/shared/modules/role/role.model'
import { AuthRoutes } from '@/shared/router/auth'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import { Routes } from '@/shared/router'

@Component({
  components: {
    AuthLayout,
    Form
  },
  methods: {
    __
  }
})
export default class Login extends Vue {
  form: FormBase = new FormBase()
    .setEndpoint('app-login')
    .setFields([
      (new Field())
        .setType(FieldTypes.email)
        .isUnderlined()
        .setKey('email')
        .setTitle(__('company.views.login.form.email')),
      (new Field())
        .setType(FieldTypes.password)
        .isUnderlined()
        .setKey('password')
        .setTitle(__('company.views.login.form.password')),
    ])
    .setOnSuccess(this.onSuccess)
    .setSubmit({
      text: __('company.views.login.form.submit'),
      position: Positions.center,
      class: 'auth-submit'
    })

  showContent = false

  mounted() {
    this.redirect(this.user)
  }

  async onSuccess(response: any) {
    tokenHelper.setToken(response.access_token)
    await this.$store.dispatch(AuthActions.me)
      .then(async (user: User) => {
        await this.$store.dispatch(CompanyActions.setCompany, user.company)

        await this.redirect(user)
      })
  }

  async redirect(user: User) {
    if (! user) {
      this.showContent = true
      return
    }

    const redirectAfterLogin = localStorage.getItem('redirectAfterLogin');
    if (redirectAfterLogin) {
      localStorage.removeItem('redirectAfterLogin');
      return this.$router.push(redirectAfterLogin);
    }

    if (user.company) {
      await this.$router.push({ name: CompanyRoutes.index, params: { company: user.company.slug } })
      return
    }

    if (user.role.level === RoleLevel.superadmin) await this.$router.push({ name: AdminRoutes.index })
    this.showContent = true
  }

  goToRemindPassword(): void {
    this.$router.push({ name: AuthRoutes.remindPassword })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }
}
